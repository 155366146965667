import { useContext } from 'react';
import { CartContext } from './Cart.context';

const useCart = () => {
  const cartContext = useContext(CartContext);

  return cartContext;
};

export default useCart;
