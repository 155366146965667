export const STOCK_STATUS = {
  IN_STOCK: 'In-Stock',
  LIMITED_SUPPLIES: 'Limited Supplies',
  SOLD_OUT: 'Sold out'
};

export const LOCATION_TYPE = {
  RESTAURANT: 'Restaurant',
  SHIPPING_CENTER: 'Shipping Center',
  CORPORATE: 'Corporate'
};
