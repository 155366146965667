/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import sidekickInit from '../../utils/sidekick/init';
import useUser from '../../utils/hooks/useUser';
import Image, { ImagePropTypes } from '../Image';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';
import ErrorBoundary from '../ErrorBoundary';
import ProfileIcon from '../../assets/icons/Profile';
import CartIcon from '../../assets/icons/Cart';
import getPath from '../../utils/getPath';
import useStyles from './GlobalHeader.styles';
import useCart from '../Cart/useCart';
import useEscape from '../../utils/hooks/UseEscape';
import { firebaseInit, firebaseApp } from '../../utils/auth/initFirebase';
import { useAppProvider } from '../../../AppProvider/AppProvider.tsx';
// import { getAuth, signOut } from 'firebase/auth';

const SubnavCardPropTypes = {
  introText: PropTypes.string.isRequired,
  primaryLink: PropTypes.shape(ElementLinkPropTypes).isRequired,
  secondaryLinks: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)),
  subNavCardStyle: PropTypes.string.isRequired
};

const TopNavItemPropTypes = {
  subnavCards: PropTypes.arrayOf(PropTypes.shape(SubnavCardPropTypes)).isRequired,
  title: PropTypes.shape(ElementLinkPropTypes).isRequired
};

const LogoLinkPropTypes = {
  as: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export const GlobalHeaderPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  logo: PropTypes.shape(ImagePropTypes).isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoLink: PropTypes.shape(LogoLinkPropTypes).isRequired,
  showLoginMenu: PropTypes.bool,
  hideMobileMenu: PropTypes.bool.isRequired,
  accountSubnavLinks: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  accountOperatorSubnavLinks: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  subnavToutImage: PropTypes.shape(ImagePropTypes).isRequired,
  subnavToutLink: PropTypes.shape(ElementLinkPropTypes).isRequired,
  topNavItems: PropTypes.arrayOf(PropTypes.shape(TopNavItemPropTypes)).isRequired,
  globalSettings: PropTypes.shape({})
};

function GlobalHeader({
  _id,
  _contentTypeId,
  internalTitle,
  logo,
  showLoginMenu,
  hideMobileMenu,
  topNavItems,
  globalSettings,
  logoLink
}) {
  const { t, lang } = useTranslation();
  const { user, profile } = useUser();
  // const client = useApolloClient();
  const classes = useStyles();
  const [mobile, setMobile] = useState(false);
  const [openId, setOpenId] = useState();
  const [hovered, setHovered] = useState();
  const [lastItem, setLastItem] = useState();
  const [noLogin, setNoLogin] = useState(true);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedIn = !!user;
  const firstName = profile && profile.firstName;
  const { totalProducts } = useCart();
  const { contentGroup } = useAppProvider();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleOpenMenu = () => setMobile((value) => !value);
  const handleOpen = (id) => () => setOpenId((prev) => prev !== id && id);
  const handleLogOut = async () => {
    const { getAuth, signOut } = await import('firebase/auth');
    await firebaseInit;
    const auth = getAuth(firebaseApp);
    await fetch('/api/v1/logout');
    await signOut(auth);
  };

  const showLogin = (language) => {
    switch (language) {
      case 'ca':
      case 'fr-ca':
      case 'me-en':
      case 'nz-en':
      case 'au-en':
      case 'au-gb':
      case 'hk':
      case 'hk-en':
      case 'en-CA':
      case 'en-AE':
      case 'en-NZ':
      case 'en-AU':
      case 'en-GB':
      case 'en-HK':
      case 'zh-Hant-HK':
      case 'cn':
      case 'cn-en':
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    setNoLogin(!showLogin(lang));
  }, [lang]);

  const createLinkClickHandler = (link) => () => {
    setMobile(false);
    if (!window?.dataLayer) return;
    window.dataLayer.push({
      event: 'menu_navigation',
      menu_type: 'header',
      menu_button: link?.linkText,
      link_url: link?.as ?? link.href,
      content_group: contentGroup
    });
  };

  const handleMouseOver = (id) => () => setHovered(id);

  const handleClick = (id) => () => {
    if (hovered === id) {
      setHovered(undefined);
    } else {
      setHovered(id);
    }
  };

  const handleFocusMenu = (id) => () => {
    if (hovered !== id) {
      setHovered(undefined);
    }
  };

  const handleFocusToggle = (id) => () => {
    if (hovered === id) {
      setHovered(undefined);
    }
  };

  const eventsKey = [];

  const closeLastFlyOut = (event) => {
    eventsKey.push(event.key);
    if (
      eventsKey[eventsKey.length - 2] !== 'Shift' &&
      event.key === 'Tab' &&
      event.target.id === lastItem
    ) {
      setHovered(undefined);
    }
  };

  const handleMouseLeave = () => setHovered(undefined);
  useEscape(() => setHovered(undefined));

  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  const { sidekicker: globalSettingsSidekicker } = sidekickInit(globalSettings);

  const getLastItemFlyOut = () => {
    const lastTopNav = topNavItems[topNavItems.length - 1]?.subnavCards;
    const lastSubNav = lastTopNav?.[lastTopNav.length - 1]?.secondaryLinks;
    const lastSubNavPrimaryLinkId = lastTopNav?.[lastTopNav.length - 1]?.primaryLink?._id;
    const lastSecondaryLinksId = lastSubNav?.[lastSubNav.length - 1]?._id;
    if (lastSecondaryLinksId) {
      setLastItem(lastSecondaryLinksId);
    } else if (lastSubNavPrimaryLinkId) {
      setLastItem(lastSubNavPrimaryLinkId);
    } else {
      setLastItem(null);
    }
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape' && mobile) {
        handleOpenMenu();
      }
    },
    [mobile]
  );

  useEffect(() => {
    getLastItemFlyOut();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (mobile) {
      const main = document.querySelector('main');
      const footer = document.querySelector('footer');
      main.style.display = 'none';
      footer.style.display = 'none';
    } else {
      const main = document.querySelector('main');
      const footer = document.querySelector('footer');
      main.style.display = 'block';
      footer.style.display = 'block';
    }
  }, [mobile]);

  useEffect(() => {
    if (isDesktop) {
      setMobile(false);
    }
  }, [isDesktop]);

  return (
    <ErrorBoundary>
      <a
        aria-label="Skip to main content"
        className={cx('sr-only sr-only-focusable', classes.srOnly)}
        href="#maincontent">
        Skip to main content.
      </a>
      <header
        tabIndex={mobile ? '-1' : '0'}
        role={mobile ? 'dialog' : 'toolbar'}
        aria-modal={mobile}
        className={cx('d-print-none', classes.header)}
        onKeyDown={closeLastFlyOut}
        {...sidekicker('Global Header')}>
        <nav
          className={cx(classes.navbar, { open: mobile, mobileOpen: mobile })}
          data-testid="GlobalHeader">
          <button
            className={cx(classes.menu, { open: mobile, [classes.hideMenu]: hideMobileMenu })}
            type="button"
            aria-expanded={mobile}
            aria-label={
              mobile ? 'close main navigation menu' : 'press enter to open main navigation menu'
            }
            onClick={handleOpenMenu}>
            <span aria-hidden="true" />
            <span className="sr-only">Menu</span>
          </button>
          <a
            href={getPath({ path: logoLink?.as, locale: lang })}
            onClick={createLinkClickHandler({ linkText: 'Home', as: logoLink?.as })}
            className={classes.logo}
            aria-label={logoLink?.label ?? 'Visit home page'}
            title="Visit home page"
            {...sidekicker('Company Logo')}>
            {/* ADA compliance: Pass empty description for logo so alt text is not added but handled by wrapping <a> tag */}
            <Image testId="Logo" image={{ ...logo, description: '' }} priority columns={3} />
          </a>
          <ul className={cx({ open: mobile })} {...sidekicker('Top Nav Items')}>
            {topNavItems.map((topNavItem) => {
              // eslint-disable-next-line no-param-reassign
              topNavItem.title = topNavItem.titleLink;
              const { sidekicker: navItemSidekicker } = sidekickInit(topNavItem);
              const singleSubnavItem =
                (topNavItem && topNavItem.subnavCards && topNavItem.subnavCards[0]) || {};
              const singleSubnavPrimaryLinkItem = singleSubnavItem.primaryLink || {};
              const { subnavCards } = topNavItem;
              const subnavCardId = subnavCards && topNavItem?.subnavCards[0]._id;

              return (
                <React.Fragment key={topNavItem._id}>
                  <li
                    data-testid="TopNavItem"
                    key={topNavItem._id}
                    className={cx('navItemGroup', { noLogin: noLogin || !showLoginMenu })}
                    {...navItemSidekicker('Top Nav Item')}>
                    {mobile ? (
                      <button
                        aria-label={`press enter to open ${topNavItem.title.linkText} options`}
                        aria-expanded={topNavItem._id === openId}
                        id={topNavItem._id}
                        className={cx(classes.accordion, { open: topNavItem._id === openId })}
                        onClick={handleOpen(topNavItem._id)}
                        {...navItemSidekicker('Title Link')}>
                        {topNavItem.title.linkText}
                      </button>
                    ) : (
                      <>
                        <ElementLink
                          href={topNavItem.title.href}
                          onClick={createLinkClickHandler(topNavItem.title)}
                          as={topNavItem.title.as}
                          trackingId={topNavItem.title.trackingId}
                          style={topNavItem.title.style}
                          linkText={topNavItem.title.linkText}
                          isModal={topNavItem.title.isModal}
                          download={topNavItem.title.download}
                          // TODO: Enable in ElementLink
                          onMouseEnter={handleMouseOver(subnavCards && subnavCardId)}
                          onMouseLeave={handleMouseLeave}
                          className={cx('topNavLink', 'mainLink')}
                          onFocus={handleFocusMenu(subnavCards && subnavCardId)}
                          aria-controls={`panel-${topNavItem._id}`}
                          noAriaLabel
                          {...navItemSidekicker('Title Link')}>
                          <span>{topNavItem.title.linkText}</span>
                        </ElementLink>
                      </>
                    )}
                    {!mobile && subnavCards && subnavCards.length >= 1 ? (
                      <ToggleButton
                        onClick={handleClick(subnavCards && subnavCardId)}
                        onMouseEnter={handleMouseOver(subnavCards && subnavCardId)}
                        onMouseLeave={handleMouseLeave}
                        onFocus={handleFocusToggle(subnavCards && subnavCardId)}
                        className={cx(classes.toggleButton, 'topNavLink')}
                        hasMenu
                        aria-haspopup="true"
                        aria-expanded={hovered === singleSubnavItem._id}
                        aria-label={`Show ${topNavItem.title.linkText} submenu `}
                        aria-controls={`panel-${topNavItem._id}`}
                        style={{ border: 'none', padding: 0 }}>
                        <KeyboardArrowDownIcon
                          className={
                            hovered === singleSubnavItem._id
                              ? classes.keyboardArrow
                              : classes.keyboardArrowBack
                          }
                        />
                      </ToggleButton>
                    ) : null}
                    {subnavCards && subnavCards.length === 1 && (
                      <ul
                        data-testid="SubNav"
                        id={`panel-${topNavItem._id}`}
                        onMouseEnter={handleMouseOver(singleSubnavItem._id)}
                        onMouseLeave={handleMouseLeave}
                        className={cx('subnav', classes.subnav, {
                          open: topNavItem._id === openId,
                          [classes.subnavOpen]: !mobile && hovered === singleSubnavItem._id
                        })}
                        {...navItemSidekicker('Subnav Card')}>
                        <li
                          className={cx('navItem', {
                            open: topNavItem._id === openId
                          })}>
                          <ElementLink
                            id={singleSubnavPrimaryLinkItem._id}
                            href={singleSubnavPrimaryLinkItem.href}
                            onClick={createLinkClickHandler(singleSubnavPrimaryLinkItem)}
                            as={singleSubnavPrimaryLinkItem.as}
                            trackingId={singleSubnavPrimaryLinkItem.trackingId}
                            style={singleSubnavPrimaryLinkItem.style}
                            linkText={singleSubnavPrimaryLinkItem.linkText}
                            isModal={singleSubnavPrimaryLinkItem.isModal}
                            download={singleSubnavPrimaryLinkItem.download}
                            noAriaLabel
                            {...sidekickInit(singleSubnavPrimaryLinkItem).sidekicker(
                              'Primary Link'
                            )}>
                            {singleSubnavPrimaryLinkItem.linkText}
                          </ElementLink>
                          <p {...sidekickInit(singleSubnavItem).sidekicker('Intro Text')}>
                            {singleSubnavItem.introTextString}
                          </p>
                        </li>
                        {singleSubnavItem.secondaryLinks &&
                          singleSubnavItem.secondaryLinks.map((secondaryLink) => (
                            <li
                              key={secondaryLink.internalTitle}
                              className={cx('navItem', { open: topNavItem._id === openId })}>
                              <ElementLink
                                id={secondaryLink._id}
                                href={secondaryLink.href}
                                onClick={createLinkClickHandler(secondaryLink)}
                                as={secondaryLink.as}
                                trackingId={secondaryLink.trackingId}
                                style={secondaryLink.style}
                                className={classes.arrowLink}
                                linkText={secondaryLink.linkText}
                                isModal={secondaryLink.isModal}
                                download={secondaryLink.download}
                                noAriaLabel
                                {...sidekickInit(secondaryLink).sidekicker('Secondary Link')}>
                                {secondaryLink.linkText}
                              </ElementLink>
                            </li>
                          ))}
                      </ul>
                    )}
                    {subnavCards && subnavCards.length > 1 && (
                      <section
                        id={`panel-${topNavItem._id}`}
                        onMouseEnter={handleMouseOver(singleSubnavItem._id)}
                        onMouseLeave={handleMouseLeave}
                        {...(mobile && { 'aria-labelledby': topNavItem._id })}
                        className={cx(classes.subnav, 'subnav', {
                          open: topNavItem._id === openId,
                          [classes.subnavOpen]: !mobile && hovered === singleSubnavItem._id
                        })}
                        {...navItemSidekicker('Subnav Cards')}>
                        <div className={cx('navGroupSet', { open: topNavItem._id === openId })}>
                          {subnavCards?.map((subnavCard) => {
                            const { sidekicker: subnavCardSidekicker } = sidekickInit(subnavCard);
                            return (
                              <ul
                                key={subnavCard._id}
                                className={cx('navGroup', { open: topNavItem._id === openId })}
                                {...subnavCardSidekicker('Subnav Card')}>
                                <li className={cx('navItem', { open: topNavItem._id === openId })}>
                                  <ElementLink
                                    id={subnavCard.primaryLink._id}
                                    href={subnavCard.primaryLink.href}
                                    onClick={createLinkClickHandler(subnavCard.primaryLink)}
                                    as={subnavCard.primaryLink.as}
                                    trackingId={subnavCard.primaryLink.trackingId}
                                    style={subnavCard.primaryLink.style}
                                    linkText={subnavCard.primaryLink.linkText}
                                    isModal={subnavCard.primaryLink.isModal}
                                    download={subnavCard.primaryLink.download}
                                    noAriaLabel
                                    {...sidekickInit(subnavCard.primaryLink).sidekicker(
                                      'Primary Link'
                                    )}>
                                    {subnavCard.primaryLink.linkText}
                                  </ElementLink>
                                  <p {...subnavCardSidekicker('Intro Text')}>
                                    {subnavCard.introTextString}
                                  </p>
                                </li>
                                {subnavCard?.secondaryLinks &&
                                  subnavCard?.secondaryLinks.map((secondaryLink) => {
                                    return (
                                      <li
                                        key={secondaryLink.internalTitle}
                                        className={cx('navItem', {
                                          open: topNavItem._id === openId
                                        })}>
                                        <ElementLink
                                          id={secondaryLink._id}
                                          href={secondaryLink.href}
                                          onClick={createLinkClickHandler(secondaryLink)}
                                          as={secondaryLink.as}
                                          trackingId={secondaryLink.trackingId}
                                          style={secondaryLink.style}
                                          linkText={secondaryLink.linkText}
                                          isModal={secondaryLink.isModal}
                                          download={secondaryLink.download}
                                          noAriaLabel
                                          {...sidekickInit(secondaryLink).sidekicker(
                                            'Secondary Link'
                                          )}>
                                          {secondaryLink.linkText}
                                        </ElementLink>
                                      </li>
                                    );
                                  })}
                              </ul>
                            );
                          })}
                        </div>
                      </section>
                    )}
                  </li>
                </React.Fragment>
              );
            })}
            {!isLoggedIn && !noLogin && showLoginMenu && (
              <li>
                <a
                  href="/auth?type=sign-in"
                  aria-haspopup="true"
                  className={cx(classes.signin, { open: openId === 'login' })}
                  onClick={handleOpen('login')}
                  data-testid="Auth-signin"
                  {...globalSettingsSidekicker('Localization Lookup - loginNavLink')}>
                  <ProfileIcon /> {t('common:loginNavLink')}
                </a>
                <div
                  className={cx('subnav', classes.subnav, { open: openId === 'login' })}
                  onMouseEnter={handleMouseOver(openId)}
                  onMouseLeave={handleMouseLeave}
                  aria-expanded={hovered === openId}>
                  <div className={cx('navGroupSet', { open: openId === 'login' })}>
                    <ul className={cx('navGroup', { open: openId === 'login' })}>
                      <li className={cx('navItem', { open: openId === 'login' })}>
                        <a
                          href="/auth?type=sign-in"
                          className={classes.arrowLink}
                          {...globalSettingsSidekicker(
                            'Localization Lookup - operatorLoginLinkLabel'
                          )}>
                          {t('common:operatorLoginLinkLabel')}
                          <div className={classes.arrowWrap}>
                            <span className={classes.arrow} role="none" />
                          </div>
                        </a>
                        <p
                          {...globalSettingsSidekicker(
                            'Localization Lookup - operatorCreateAccountDescription'
                          )}>
                          {t('common:operatorCreateAccountDescription')}
                        </p>
                        <small>
                          <a
                            href="/auth?type=sign-up"
                            className="navLink"
                            {...globalSettingsSidekicker(
                              'Localization Lookup - operatorCreateAccountLinkLabel'
                            )}>
                            {t('common:operatorCreateAccountLinkLabel')}
                          </a>
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            )}

            {isLoggedIn && !noLogin && (
              <li>
                <a
                  href="#"
                  data-testid="Auth-loggedin"
                  className={cx(classes.signin, {
                    open: openId === 'login'
                  })}>
                  <ProfileIcon /> {`Hi, ${firstName}`}
                </a>
                <div
                  className={cx('subnav', classes.subnav, { open: openId === 'login' })}
                  {...sidekicker('Account Links')}>
                  <div className={cx('navItem', { open: openId === 'login' })}>
                    <a
                      href="#"
                      className={classes.ctaSolid}
                      onClick={handleLogOut}
                      {...globalSettingsSidekicker('Localization Lookup - logOutButtonLabel')}>
                      {t('common:logOutButtonLabel')}
                    </a>
                  </div>
                </div>
              </li>
            )}
          </ul>
          {isLoggedIn && !noLogin && (
            <div className={classes.linkContainer}>
              <a href="/account/profile" className="avatarLink">
                <ProfileIcon />
              </a>
              <Link legacyBehavior title="Cart" href="/foodservice/store/cart" passHref>
                <a
                  href="/foodservice/store/cart"
                  aria-label={
                    totalProducts > 0
                      ? `${totalProducts} items in Shopping Cart, proceed to checkout`
                      : 'View empty shopping cart'
                  }>
                  <CartIcon />
                </a>
              </Link>
            </div>
          )}
        </nav>
      </header>
    </ErrorBoundary>
  );
}

GlobalHeader.propTypes = GlobalHeaderPropTypes;

GlobalHeader.defaultProps = {
  globalSettings: {},
  showLoginMenu: false
};

export default GlobalHeader;
