import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL;

const handleResponse = (response) => response?.data;
export const getOperatorCart = () => axios.get(`${baseUrl}/store/get_cart`).then(handleResponse);
export const saveOperatorCart = (cart) =>
  axios.post(`${baseUrl}/store/save_cart`, cart).then(handleResponse);
export const getProfile = () => axios.get(`${baseUrl}/loyalty/profile`).then(handleResponse);

export default {
  getOperatorCart,
  saveOperatorCart,
  getProfile
};
