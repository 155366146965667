import { makeStyles } from '@material-ui/core/styles';
import { borderRadius, lineHeight } from '@material-ui/system';

export default makeStyles((theme) => ({
  srOnly: {
    'zIndex': 10001,
    'position': 'fixed !important',
    'top': 0,
    'margin': '0px 50px',
    'overflow': 'hidden',
    'clip': 'rect(0, 0, 0, 0)',
    'white-space': 'nowrap',
    'border': 0,
    'fontSize': 18
  },
  header: {
    'z-index': 10000,
    'cursor': 'default',
    'width': '100%',
    'height': '90px',

    [theme.breakpoints.down('md')]: {
      height: '70px'
    }
  },
  navbar: {
    'display': 'flex',
    'flex-wrap': 'nowrap',
    'width': '100%',
    'position': 'fixed',
    'top': 0,
    'z-index': 10000,
    'overflow': 'visible',
    'padding': '0 50px',
    'background-color': theme.palette.extra.white,
    'box-shadow': '0 2px 5px 0 rgba(0, 0, 0, 0.06)',

    [theme.breakpoints.down('md')]: {
      'justify-content': 'center',
      'height': '70px',

      '&.mobileOpen': {
        'height': '100vh',
        'overflow-y': 'scroll'
      }
    },

    '& > ul': {
      'display': 'flex',
      'list-style': 'none',
      'margin': '0 0 0 auto',
      'padding': 0,

      [theme.breakpoints.down('md')]: {
        'display': 'none',
        'position': 'absolute',
        'top': '70px',
        'left': 0,
        'flex-direction': 'column',
        'width': '100%',
        'padding': '16px 34px 0',
        'background-color': theme.palette.extra.white,
        'border-top': '1px solid #eee',

        '&.open': {
          'display': 'flex',
          'padding': 0,
          'padding-bottom': 150,
          'height': '93vh',
          'overflow-y': 'auto',
          'background-color': '#fff'
        }
      }
    },

    [theme.breakpoints.up('lg')]: {
      '& li': {
        '&:last-child > a::before, &:nth-last-child(1) > a::before': {
          'content': "''",
          'position': 'absolute',
          'top': 32,
          'left': 0,
          'height': 30,
          'border-left': '1px solid #d0d0d0'
        },

        '&.noLogin': {
          '&:last-child > a::before, &:nth-last-child(1) > a::before': {
            'border-left': '0 solid #d0d0d0'
          }
        }
      }
    },

    '& .navItem': {
      'padding': 0,
      '& > a': {
        borderBottom: 'none',
        // up md
        [theme.breakpoints.up('md')]: {
          'padding': 0,
          'lineHeight': 1.2,
          '&::before': {
            display: 'none'
          }
        }
      },
      '&:last-child': {
        paddingBottom: 0
      }
    },

    '& li': {
      'position': 'relative',

      '&:hover .topNavLink': {
        color: '#4F0423'
      },

      '& .mainLink': {
        '&:focus ': {
          'outline': 'none',
          'boxShadow': 'none',
          '& span': {
            outline: '0.0625rem solid #4f0423',
            outlineOffset: 2,
            borderRadius: 4
          }
        }
      },

      '& > a': {
        'display': 'inline-block',
        'height': '100%',
        'padding': '0 .5rem 0 2rem',
        'white-space': 'nowrap',
        'cursor': 'pointer',
        'border-bottom': '4px solid transparent',
        'line-height': '4',
        'text-decoration': 'none',

        [theme.breakpoints.up('lg')]: {
          'transition': 'color 0.3s',

          '&.active': {
            'color': theme.palette.extra['radical-red'],

            '& + .subnav': {
              display: 'block'
            }
          },

          '&:hover': {
            '& + .subnav': {
              display: 'block'
            }
          }
        },

        // Custom media query for specific width range from 1024px to 1052px
        '@media (min-width: 1024px) and (max-width: 1052px)': {
          padding: '0 0.5rem 0 1rem'
        },

        // @include media-breakpoint-down(lg) {
        [theme.breakpoints.down('md')]: {
          'height': 'auto',

          '&::before, &::after': {
            'content': "''",
            'position': 'absolute',
            'top': '50%',
            'background-color': theme.palette.extra.black,
            'transform': 'translateY(-50%)',
            'transition': 'transform 0.25s ease-out'
          },

          '&.open': {
            '&::before': {
              content: 'none'
            },

            '&::after': {
              'background-color': theme.palette.extra.blue
            }
          }
        }

        // '@media (min-width: 1280px)': {
        //   padding: '0 1.2vw'
        // }
      },

      '& h2, a': {
        'color': '#260212',
        'font-family': "'SansMeatCompressedBold', sans-serif",
        'text-transform': 'uppercase'
      },

      '& h2': {
        'margin': '20px 0 6px',
        'font-size': 36,
        'line-height': '1',
        'text-indent': 0
      },

      '& a': {
        'position': 'relative',
        'font-size': 22,
        'letter-spacing': 0.2,
        'user-select': 'none',
        'text-decoration': 'none',

        '&:hover': {
          color: theme.palette.extra['radical-red']
        },

        // @include media-breakpoint-down(lg) {
        [theme.breakpoints.down('md')]: {
          'width': '100%',
          'padding': '10px 0',
          'font-size': 24,
          'line-height': '2',

          '&:focus': {
            outline: 'none'
          }
        },

        '&.navLink': {
          'display': 'inline',
          'font-family': "'SansMeatProportionalBold', sans-serif",
          'font-size': 'inherit',
          'text-transform': 'none'
        }
      },

      [theme.breakpoints.down('md')]: {
        'padding': '0 34px',

        '&:last-child': {
          'padding-bottom': '50px'
        },

        '& > a': {
          'border-bottom': '1px solid #eee',

          '&.open': {
            'border-color': '#e3e3e3',
            'color': theme.palette.extra.blue
          }
        }
      }
    }
  },

  subnav: {
    'display': 'none',
    'width': 'auto',
    'padding': '14px 28px',
    'background-color': '#f7f7f7',

    [theme.breakpoints.up('lg')]: {
      'position': 'absolute',
      'top': 90,
      'right': -40,
      'min-width': 300,
      'padding': '40px 40px 20px',
      'box-shadow': '2px 3px 4px 0 rgba(0, 0, 0, 0.2)',

      '&:hover': {
        'display': 'block',
        '& .MuiSvgIcon-root': {
          color: 'blue'
        }
      }
    },

    // @include media-breakpoint-down(md) {
    [theme.breakpoints.down('md')]: {
      '&.open': {
        'display': 'block',

        '& p': {
          display: 'none'
        }
      },

      '& .navGroupSet': {
        'flex-direction': 'column'
      },

      '& .navGroup, & .navGroup:not(:last-child)': {
        padding: 0,
        border: 0
      },

      '& .navItem': {
        'margin': 0,

        '&:hover a': {
          'color': theme.palette.extra['radical-red'],
          'text-decoration': 'none'
        }
      },

      '& .subLink': {
        'padding-left': 16,
        'box-shadow': 'inset 0 -1px 0 0 #ddd'
      },

      '&.signin .navGroupSet.open': {
        'padding-left': 16,
        'padding-bottom': 50
      }
    },

    '& .navGroupSet': {
      display: 'flex'
    },

    '& .navGroup': {
      [theme.breakpoints.up('lg')]: {
        'padding': '0 30px',

        '&:first-child': {
          'padding-left': 0
        },

        '&:last-child': {
          'padding-right': 0
        },

        '&:not(:last-child)': {
          'border-right': '1px solid #ddd'
        }
      },

      '@media (min-width: 1100px)': {
        padding: '0 40px'
      }
    },

    '& .navItem': {
      'min-width': 220,
      'margin-bottom': 14,

      [theme.breakpoints.up('lg')]: {
        'min-width': 220,
        'margin-bottom': 22
      },

      // '@include media-breakpoint-up(xl)': {
      '@media (min-width: 1440.98px)': {
        'min-width': 240
      },

      '& a': {
        'font-size': 22,

        // @include media-breakpoint-down(lg) {
        [theme.breakpoints.down('md')]: {
          'line-height': 1.2
        }
      }
    },

    '& a': {
      'display': 'block',
      'margin': 0,
      'cursor': 'pointer',
      'line-height': 1.2,

      '&:hover': {
        'color': theme.palette.extra.black,
        'text-decoration': 'none'
      }
    },

    '& p': {
      'margin': '10px 0 26px',
      'color': '#555',
      'font-size': 14,
      'line-height': 1.4
    }
  },

  logo: {
    'align-self': 'center',
    'display': 'block',
    'height': 30,

    '& img': {
      height: 30,
      width: 'auto'
    },

    [theme.breakpoints.up('lg')]: {
      'width': 150,
      'min-width': 150,
      'height': 37,
      'margin-right': 50,

      '& img': {
        height: 36.8,
        width: 'auto'
      }
    },

    // @include media-breakpoint-down(lg) {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 20
    }
  },

  ctaSolid: {
    'margin-top': 30,
    'padding': '1rem',
    'background-color': theme.palette.extra['near-black'],
    'text-align': 'center',
    'color': `${theme.palette.extra.white} !important`,
    'font-size': 24,

    '&:hover': {
      'background-color': theme.palette.extra['dark-charcoal'],
      'color': theme.palette.extra.white
    }
  },

  toutImage: {
    width: 300,
    height: '100%',

    // @include media-breakpoint-down(md) {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto',
      width: 200
    }
  },

  linkContainer: {
    'margin-bottom': 4,

    '& a': {
      'position': 'relative',
      'font-size': '1.375rem',
      'letter-spacing': '0.0125rem',
      '-webkit-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
      'display': 'flex',
      'align-items': 'center',
      'width': '100%',
      'height': '100%',
      'padding': '0 10px'
    },

    '& .avatarLink': {
      display: 'none',

      [theme.breakpoints.down('md')]: {
        display: 'flex'
      }
    },

    [theme.breakpoints.down('md')]: {
      'position': 'absolute',
      'top': '1.5rem',
      'right': '1.5rem',
      'display': 'flex',
      'align-items': 'center'
    }
  },

  hideMenu: {
    display: 'none'
  },

  menu: {
    'position': 'absolute',
    'top': 14,
    'left': 24,
    'width': 45,
    'height': 45,
    'margin': 0,
    'padding': 10,
    'cursor': 'pointer',
    'background-color': 'transparent',
    'border': 0,
    'overflow': 'visible',
    'transition-duration': '0.15s',
    'transition-property': 'opacity, filter',
    'transition-timing-function': 'linear',

    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },

    '& span, & span::before, & span::after': {
      'position': 'absolute',
      'width': 25,
      'height': 3,
      'background-color': theme.palette.extra.black
    },

    '& span': {
      'left': 10,
      'top': 13,

      '&::before, &::after': {
        content: '""',
        left: 0,
        transition: `top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s`
      },

      '&::before': {
        top: 8
      },

      '&::after': {
        top: 16
      }
    },

    '&.open': {
      'margin-left': 4,

      '& span': {
        'height': 0,
        'transform': 'rotate(45deg)',

        '&::before': {
          transform: 'rotate(90deg)'
        },

        '&::after': {
          top: 16,
          transform: 'translateY(-8px)'
        }
      }
    }
  },

  arrowLink: {
    '&:hover': {
      '& > div': {
        transform: 'translateX(17px)',
        transition: 'margin-left 0.2s ease-in-out'
      }
    }
  },

  arrowWrap: {
    display: 'inline-block',
    position: 'relative',
    width: 15,
    height: 15,
    transform: 'translateX(15px)',
    transition: 'transform 0.2s ease-in-out'
  },

  arrow: {
    'position': 'absolute',
    'left': -7,
    'top': 2,
    'width': 10,
    'height': 10,
    'transform': 'rotate(315deg)',

    '&::before, &::after': {
      content: '""',
      position: 'absolute'
    },

    '&::before': {
      'right': -1,
      'bottom': 0,
      'width': 2,
      'height': 14,
      'background-color': '#666',
      'transform-origin': 'bottom',
      'transform': 'rotate(-45deg)'
    },

    '&::after': {
      'top': 0,
      'left': 0,
      'width': 10,
      'height': 10,
      'border-color': '#666',
      'border-bottom-style': 'solid',
      'border-bottom-width': 2,
      'border-right-style': 'solid',
      'border-right-width': 2
    }
  },

  signin: {
    'padding-left': 24,

    '& svg': {
      width: 16,
      height: 18,
      margin: '-3px 12px 0 0'
    },

    // @include media-breakpoint-down(md) {
    [theme.breakpoints.down('md')]: {
      '~ .subnav': {
        'padding-bottom': 40
      }
    }
  },

  subnavOpen: {
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    }
  },

  keyboardArrow: {
    transform: 'rotate(180deg)',
    transition: '0.17s all ease-in-out',
    color: theme.palette.extra['radical-red']
  },
  keyboardArrowBack: {
    transition: '0.17s all ease-in-out',
    color: theme.palette.extra.black
  },
  accordion: {
    'position': 'relative',
    'backgroundColor': 'transparent',
    'border': 'none',
    'padding': theme.spacing(1.25, 0),
    'lineHeight': '2',
    'fontSize': 24,
    'width': '100%',
    'height': 'auto',
    'borderBottom': '1px solid #eee',
    'letterSpacing': '0.2px',
    'color': '#260212',
    'textTransform': 'uppercase',
    'textAlign': 'left',
    'fontFamily': "'SansMeatCompressedBold', sans-serif",
    '&::before, &::after': {
      'content': "''",
      'position': 'absolute',
      'top': '50%',
      'background-color': theme.palette.extra.black,
      'transform': 'translateY(-50%)',
      'transition': 'transform 0.25s ease-out'
    },

    '&::before': {
      right: 9,
      width: 4,
      height: 18
    },

    '&::after': {
      right: 2,
      width: 18,
      height: 4
    }
  }
}));
