import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import noop from 'lodash/noop';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getOperatorCart, saveOperatorCart } from '../../utils/api';
import { STOCK_STATUS, LOCATION_TYPE } from './constants';
import useUser from '../../utils/hooks/useUser';

const baseUrl = process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL;

export const CartContext = createContext({
  cart: {},
  locations: [],
  setLocations: noop,
  progress: 1,
  step: 'cart',
  setStep: noop,
  isLoading: false,
  setisLoading: noop,
  steps: null,
  totalProducts: null,
  error: null,
  saveCartMutation: null,
  addItem: null,
  onEditCartItem: noop,
  onAddTemporaryLocation: noop,
  onCheckAddress: noop,
  onPlaceOrder: noop,
  onChangeStep: noop
});

const CartProvider = ({ children }) => {
  const { user, profile } = useUser();
  const [locations, setLocations] = useState([]);
  const selectedLocations = useMemo(
    () => locations.filter((location) => location.checked),
    [locations]
  );
  const [step, setStep] = useState('cart');
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: cart,
    error
  } = useQuery('operatorCart', getOperatorCart, {
    enabled: !!user && !!profile && !profile?.isLoyalty
  });
  const saveCartMutation = useMutation(saveOperatorCart, {
    // Optimistically update the cache value on mutate, but store
    // the old value and return it so that it's accessible in case of
    // an error
    onMutate: async (cartData) => {
      await queryClient.cancelQueries('operatorCart');
      const previousValue = queryClient.getQueryData('operatorCart');
      queryClient.setQueryData('operatorCart', (old) => ({
        ...old,
        ...cartData
      }));
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData('operatorCart', previousValue)
    // After success or failure, refetch the operatorCart query
    // onSuccess: () => queryClient.invalidateQueries('operatorCart')
  });

  const updateItem = useCallback(
    (item) => {
      const currentValues = queryClient.getQueryData('operatorCart');
      return saveCartMutation.mutateAsync({
        ...currentValues,
        items: currentValues.items.map((x) => (x.variant.id === item.variant.id ? item : x))
      });
    },
    [queryClient]
  );

  const addItem = useCallback(
    (item) => {
      // TODO: we should remove this after we integrate the auth screens into this codebase
      const currentValues = queryClient.getQueryData('operatorCart');
      if (!user && window) {
        window.location.href = `/auth?type=sign-in&returnTo=${window.location.href}`;
        return Promise.resolve();
      }
      const cartItem = currentValues?.items?.find((x) => x.variant.id === item.variant.id);
      if (cartItem) {
        return updateItem({ ...item, quantity: cartItem.quantity + item.quantity });
      }
      return saveCartMutation.mutateAsync({
        items: [...(currentValues?.items ?? []), item]
      });
    },
    [queryClient, user]
  );

  const deleteItem = useCallback(
    (item) => {
      const currentValues = queryClient.getQueryData('operatorCart');
      return saveCartMutation.mutateAsync({
        ...currentValues,
        items: currentValues?.items?.filter((x) => x.variant.id !== item.variant.id)
      });
    },
    [queryClient]
  );

  const onEditCartItem = useCallback(
    (idx, type = 'increase') => {
      const currentValues = queryClient.getQueryData('operatorCart');
      const currItem = currentValues?.items[idx];
      switch (type) {
        case 'decrease':
          if (currItem.quantity > 1) {
            return updateItem({
              ...currItem,
              quantity: currItem.quantity - 1
            });
          }
          break;
        case 'remove':
          return deleteItem(currItem);
        default:
        case 'increase':
          return updateItem({
            ...currItem,
            quantity: currItem.quantity + 1
          });
      }
      return currItem;
    },
    [updateItem, deleteItem]
  );

  const onCheckAddress = useCallback(async (address) => {
    try {
      const formattedLocation = {
        ...address,
        apartment: '',
        postalCode: address.postal_code,
        location_type: {
          key: address.location_type,
          value: LOCATION_TYPE[address.location_type]
        },
        stockStatus: {
          key: address.stockStatus,
          value: STOCK_STATUS[address.stockStatus]
        }
      };
      const { data } = await axios.post(`${baseUrl}/usps/address`, {
        ...formattedLocation
      });
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  }, []);

  const onAddTemporaryLocation = useCallback(async (location) => {
    try {
      const { data } = await axios.post(`${baseUrl}/add_temporary_location`, {
        ...location
      });

      if (!data.error) {
        // console.log(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }, []);

  const onPlaceOrder = useCallback(async () => {
    try {
      await axios.post(`${baseUrl}/store/place_order`, {
        customer: {
          email: profile?.email,
          first_name: profile?.firstName,
          last_name: profile?.lastName
        },
        lineItems: [
          ...cart.items.map((product) => ({
            properties: [],
            quantity: product.quantity,
            variant_id: product.variant?.id?.toString()
          }))
        ],
        shippingAddresses: [
          ...selectedLocations.map(({ addressData }) => ({
            address1: addressData.address,
            address2: addressData.company,
            city: addressData.city,
            company: addressData.company,
            country: 'US',
            first_name: profile?.firstName,
            last_name: profile?.lastName,
            note: '',
            phone: addressData.phone,
            province: addressData.state_code,
            zip: addressData.postal_code
          }))
        ]
      });

      saveCartMutation.mutate({ items: [] });
    } catch (e) {
      throw new Error(e.message);
    }
  }, [locations, profile]);

  const totalProducts = useMemo(() => {
    let count = 0;
    cart?.items?.forEach((item) => {
      count += item.quantity;
    });

    return count;
  }, [cart]);

  const steps = ['cart', 'shipping', 'checkout'];
  const onChangeStep = React.useCallback(
    (x) => {
      if (!steps.includes(step)) return;
      if (x === 'shipping' && !cart?.items?.length) return;
      if (x === 'checkout' && !selectedLocations.length) return;
      setStep(x);
    },
    [locations, selectedLocations, cart]
  );
  const progress = useMemo(
    () =>
      Math.max(
        0,
        steps.findIndex((x) => x === step)
      ),
    [steps, step]
  );
  const providerValues = {
    cart,
    steps,
    step,
    locations,
    setLocations,
    setStep,
    totalProducts,
    isLoading,
    error,
    saveCartMutation,
    addItem,
    onEditCartItem,
    onAddTemporaryLocation,
    onCheckAddress,
    onPlaceOrder,
    onChangeStep,
    progress
  };

  return <CartContext.Provider value={providerValues}>{children}</CartContext.Provider>;
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const CartContextProvider = (props) => <CartProvider {...props} />;

export default CartContextProvider;
