import { useEffect } from 'react';
import md5 from 'md5';
import { gql, request } from 'graphql-request';
import { useQuery, useQueryClient } from 'react-query';
import { firebaseApp, firebaseInit } from '../auth/initFirebase';

const endpoint = '/api/v1/graphql'; // Replace this with your actual GraphQL endpoint

const USER_QUERY = gql`
  query User {
    user: me {
      id
      uid
      email
      firstName
      lastName
      isLoyalty
      isOperator
    }
  }
`;

const fetchUser = async () => {
  await firebaseInit;
  const { getAuth } = await import('firebase/auth');

  const auth = getAuth(firebaseApp);
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    const headers = { Authorization: `Bearer ${token}` };
    const data = await request(endpoint, USER_QUERY, null, headers);
    return data.user;
  }
  return null;
};

export default function useUser() {
  const queryClient = useQueryClient();
  const {
    data: user,
    isLoading,
    isFetched
  } = useQuery('user', fetchUser, {
    enabled: false // Lazy load the query
  });
  const loading = isLoading || !isFetched || user == null;

  useEffect(() => {
    let unsubscribe = null;
    const subscribe = async () => {
      await firebaseInit;
      const { getAuth, onAuthStateChanged } = await import('firebase/auth');
      const auth = getAuth(firebaseApp);
      unsubscribe = onAuthStateChanged(auth, (state) => {
        if (state) {
          queryClient.refetchQueries('user'); // Use refetchQueries to trigger the query by key
        }
      });
    };
    subscribe();
    return () => unsubscribe && unsubscribe();
  }, [queryClient]);

  useEffect(() => {
    if (global?.dataLayer) {
      // UserID
      if (user?.uid && !global?.dataLayer?.some((e) => !!e.uid)) {
        global.dataLayer.push({ uid: user?.uid });
      }
      // Email hashed
      if (user?.email && !global?.dataLayer?.some((e) => !!e.uem)) {
        global.dataLayer.push({ uem: md5(user?.email).toString() });
      }
    }
  }, [user]);

  return { user, profile: user, loading };
}
